@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #000000;
  --accent-color: #0cc0df;
  --background-color: #545454;
  --secondary-color: #858585;
  --neutral-color: #efefef;
  --font-family: "Nunito", sans-serif;
  --font-familyR: "Rubik", sans-serif;
}

@layer base {
  li {
    @apply px-5;
    @apply cursor-pointer;
  }
}

.social-icon {
  width: 100px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease-out, margin-bottom 0.3s ease-out;
}

.social-icon:hover {
  transform: translateY(-20px);
  margin-bottom: 20px;
}



